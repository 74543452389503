import React, { useEffect, useRef } from 'react';

const Snowfall = () => {
  const snowContainerRef = useRef(null);

  useEffect(() => {
    const snowContainer = snowContainerRef.current;
    const createSnowflake = () => {
      const snowflake = document.createElement('div');
      snowflake.className = 'snowflake';
      snowflake.textContent = '❄️';
      snowflake.style.left = Math.random() * 100 + 'vw';
      snowflake.style.animationDuration = Math.random() * 3 + 2 + 's'; // Between 2-5 seconds
      snowflake.style.opacity = Math.random();
      snowContainer.appendChild(snowflake);

      // Remove snowflake after animation ends
      setTimeout(() => {
        snowflake.remove();
      }, 5000);
    };

    const interval = setInterval(createSnowflake, 300); // Create snowflakes every 300ms

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return <div ref={snowContainerRef} className="snow-container"></div>;
};

export default Snowfall;
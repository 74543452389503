import React from 'react';
import Footer from './components/Footer';
import WelcomePage from './components/WelcomePage';
function App() {
  return (
    <div>
      <WelcomePage />
      <Footer />
    </div>
  );
}

export default App;

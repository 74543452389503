import React, { useState, useEffect } from 'react';
import './WelcomePage.css';
import Snowfall from './Snowfall';
import Confetti from 'react-dom-confetti';

const WelcomePage = () => {
  const [extraSnow, setExtraSnow] = useState(false);
  const [confettiActive, setConfettiActive] = useState(false);
  const [typedKeys, setTypedKeys] = useState("");

  const handleSnowflakeClick = () => {
    setExtraSnow(true);
    setTimeout(() => setExtraSnow(false), 5000); // Reset after 5 seconds
  };

  const handleKeyDown = (event) => {
    const key = event.key.toUpperCase();
    setTypedKeys((prev) => (prev + key).slice(-4));

    if ((typedKeys + key).slice(-4) === "WLXM") {
      setConfettiActive(true);
      setTimeout(() => setConfettiActive(false), 3000); // Show confetti for 3 seconds
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [typedKeys]);

  return (
    <>
      {/* Navbar */}
      <div className="navbar">
        <div className="navbar-content">
          <h1 className="welcome-title">🎄 We Love X-MAS ($WLXM) - Launching Soon! 🎅</h1>
          <p className="welcome-subtitle">
            Get ready for exclusive airdrops and festive cheer! 🚀
          </p>
          <div className="social-links">
            <a
              href="https://x.com/welovexmascoin"
              target="_blank"
              rel="noopener noreferrer"
            >
              X
            </a>
            <a
              href="https://t.me/WeLoveXMAS"
              target="_blank"
              rel="noopener noreferrer"
            >
              Telegram
            </a>
            <span className="hidden-snowflake" onClick={handleSnowflakeClick}>❄️</span> {/* Hidden Snowflake Easter Egg */}
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="welcome-container">
        <Snowfall extraSnow={extraSnow} /> {/* Festive snow animation */}

        {/* New Section for Contract Address Dropping Soon */}
        <div className="contract-section">
          <h2 className="contract-announcement">🎁 Contract adress dropped 🎁</h2>
          <p className="contract-text">DXzDmmsrT6tR1hcPvwGDvEZBAMcxxLS98TSNWprXpump</p>
        </div>

        {/* Confetti Animation for Easter Egg */}
        <Confetti active={confettiActive} className="confetti" />

        {/* Cards Section */}
        <div className="cards-container">
          <div className="card">
            <h2 className="card-title">Our Mission</h2>
            <p className="card-text">
              We Love X-MAS ($WLXM) is all about spreading festive cheer and
              financial freedom. We are creating a community that celebrates
              both the magic of Christmas and the excitement of cryptocurrency.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">Community First</h2>
            <p className="card-text">
              The $WLXM token is powered by our vibrant community. Together, we
              bring fun, inclusivity, and festive vibes to the world of crypto.
            </p>
          </div>
          <div className="card">
            <h2 className="card-title">Join the Festivities</h2>
            <p className="card-text">
              Participate in exclusive airdrops, meme contests, and more. Join
              us on our festive journey and let’s take $WLXM to the moon! 🚀✨
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;

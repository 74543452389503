import React from 'react';

const Footer = () => (
  <footer className="footer bg-gray-800 text-white text-center p-4">
    <p>&copy; 2024 We Love X-MAS ($WLXM). All rights reserved.</p>
    <p>Smart Contract Address: <span className="text-green-400">0x1234...ABCD</span></p>
  </footer>
);

export default Footer;
